import type { ComponentType } from "react"
import { useEffect, useCallback, useState, useMemo, useRef } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    background: "#0099FF",
})

export function ThumbsUp(Component): ComponentType {
    return (props) => {
        // const inputRef = useRef(null)
        //   const handleClick = useCallback(() => inputRef.current?.click(), []);

        // useEffect(() => {
        //     setTimeout(() => {
        //         console.log("input ref", inputRef)
        //         if (inputRef.current) {
        //             console.log("clicking?")
        //             inputRef.current?.click()
        //             // inputRef.current?.dispatchEvent(
        //             //     new PointerEvent("pointerdown", {
        //             //         pointerId: 1,
        //             //         bubbles: true,
        //             //         cancelable: true,
        //             //         pointerType: "touch",
        //             //         width: 100,
        //             //         height: 100,
        //             //         isPrimary: true,
        //             //     })
        //             // )
        //         }

        //         // console.log("cool timeout 1234567", props.ref)
        //         // props.ref.click()
        //         // props.ref.pointerDown()
        //         // console.log("HELLO", props.ref)
        //     }, 10000)
        // }, [inputRef])
        // const handleClick = useCallback(() => {
        //     console.log("clicked shell")
        // }, [])
        // return (
        //     <div
        //         id="home_modal"
        //         style={{ opacity: 0.5, position: "absolute" }}
        //         onClick={handleClick}
        //         ref={inputRef}
        //     >
        //         <Component {...props} />
        //     </div>
        // )
        // return null
        const hash = window?.location?.hash?.substr(1)
        if (hash === "thumbs-up") {
            return <Component {...props} />
        } else {
            return null
        }
    }
}

export function ThumbsDown(Component): ComponentType {
    return (props) => {
        const hash = window?.location?.hash?.substr(1)
        if (hash === "thumbs-down") {
            return <Component {...props} />
        } else {
            return null
        }
    }
}

export function withRotate(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                animate={{ rotate: 90 }}
                transition={{ duration: 2 }}
            />
        )
    }
}

export function withHover(Component): ComponentType {
    return (props) => {
        return <Component {...props} whileHover={{ scale: 1.05 }} />
    }
}

export function withRandomColor(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component
                {...props}
                animate={{
                    background: store.background,
                }}
                onClick={() => {
                    setStore({ background: randomColor() })
                }}
            />
        )
    }
}
